import React, { Suspense } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { LANDING_ROUTES } from './routes';
import SearcherContainer from 'pages/SearcherContainer';
import LoaderComponent from './components/UI/loader';
const isForSportCenter = !!process.env.REACT_APP_CENTER_KEY;

function App() {
  return (
    <HelmetProvider>
      <Suspense fallback={<LoaderComponent />}>
        <BrowserRouter>
          <Switch>
            {LANDING_ROUTES.map((route, index) => (
              <Route key={index} path={route.path} exact={route.exact}>
                <route.component />
              </Route>
            ))}
            {!isForSportCenter && <Route path='/searcher' component={SearcherContainer} />}
            <Redirect to='/' />
          </Switch>
        </BrowserRouter>
      </Suspense>
    </HelmetProvider>
  );
}

export default App;
