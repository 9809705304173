import styled, { css } from 'styled-components';
import { Layout as AntLayout, Row, Breadcrumb, Card } from 'antd';

const { Header: AntHeader } = AntLayout;
const { Content: AntContent } = AntLayout;

export const Header = styled(AntHeader)`
  background: #ffff;
  height: 80px;
  padding: 0px 31px;

  .Col-button {
    text-align: end;
  }
`;

export const Content = styled(AntContent)`
  min-height: calc(100vh - 80px);
  background: #e2e5ec;

  @media (max-width: 608px) {
    height: 100vh;
  }
`;

export const Layout = styled(AntLayout)`
  .Col-left-logo {
    height: 80px;
  }

  .Button-login {
    width: 171px;
    height: 38px;
    font-family: Roboto, sans-serif;
    font-weight: 500;
    font-size: 16px;
    border-radius: 10px;
    margin: 20px 15px 20px 0px;
  }

  @media (max-width: 608px) {
    .Col-left-logo {
      margin: auto;
    }
  }
`;

export const ContentDetailsCenter = styled(Row)`
  .ant-select-arrow {
    color: #1E3799 !important;
  }    

  .image-gallery-thumbnail.active,
  .image-gallery-thumbnail:focus {
    border: none;
  }

  .image-gallery-thumbnail:hover {
    border: none !important;
  }

  .Col-Content--infoCenter {
    max-height: calc(100vh - 80px);
    background: '#F3F6F9';
  }

  .Row-Select--menu {
    background: #f3f6f9;
    padding: 18px 20px;
    box-shadow: rgb(10 10 11 / 13%) 0px 9px 10px -6.8px inset;
  }

  .Title-Sede {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 24px;
    color: #1e3799;
    margin: 0px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .Direction-Sede {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #616fa1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 0px;
  }

  .Col-Content--Select {
    align-content: center;
    label {
      position: absolute;
      z-index: 10;
      font-size: 12px;
      top: 13px;
      left: 12px;
      font-family: 'Roboto', sans-serif;
      font-weight: 500;
    }

    .ant-select-selector {
      height: 45px;
      width: 100%;
      border-radius: 10px;
      padding-top: 14px;
      color: #1b1464;
      border-color: #dfe8f1;
      font-family: 'Roboto', sans-serif;
      font-size: 14px;
      font-weight: 600;
    }

    .Select-Centro {
      //padding-bottom: 15px;
      .ant-select-selector {
        background: #dfe8f1;
      }
      .ant-select-arrow {
        top: 50%;
      }
    }
  }

  ${(props) => 
    props.fixed && 
    css `
    .Row-info--reserva {
      position: sticky;
      bottom: 1px;
      z-index: 10;
      height: 85px;
      box-shadow: 0px -5px 15px rgba(0, 0, 0, 0.1);
      .Col-infotext {
        background: #f3f6f9;
        font-family: 'Roboto', sans-serif;
        padding: 10px 0px 10px 30px;
        p {
          margin-bottom: 0px;
        }
      }
      .ant-btn {
        border-radius: 0px;
      }
    }
  ` }

  .Row-Content--InfoCentro {
    background: #ffff;
    padding: 10px 20px;
    height: 359px;
  }

  @media (max-width: 608px) {
    .Row-Select--menu {
      padding: 18px 20px;
    }

    .Select-Centro {
      padding-bottom: 15px;
      .ant-select-arrow {
        top: 39% !important;
      }
    }

    .Col-content--BarNav {
      justify-content: end;
    }

    .Row-Content--InfoCentro {
      display: none;
    }

    .Col-Content--Select label {
      top: 6px;
    }

    .Col-infotext {
      .p-title{
        font-size: 12px;
        color: #616FA1;
        margin-bottom: 0px;

      }

      .real-amount {
        font-size: 18px;
        font-weight: 700;
        color: #616FA1;
        text-decoration: line-through;
        line-height: 15px;
      }

      .p-price {
        font-size: 30px;
        color: #00E676;
        font-weight: 700;
      }

      .amount {
        line-height: 31px;
      }
    }

    .Col-contentButton {
      .ant-btn {
        width: 100%;
        height: 100%;
        background: rgb(0, 230, 118);
        color: rgb(255, 255, 255);
        font-size: 19px;
        font-family: Roboto, sans-serif;
        border-color: transparent;
      }
    }

  }
`;

export const BarNav = styled(Breadcrumb)`
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
  padding: 0px 0px 0px 10px;
  align-content: center;
  color: #9daab8;

  .route-act {
    color: #3742fa;
    border-bottom: 2px solid;
  }

  .ant-breadcrumb-separator {
    color: #9daab8 !important;
    border-bottom: none !important;
  }

  .image-gallery-image {
    height: 167px;
  }
  .image-gallery-thumbnails {
    padding: 29px 0 0 !important;
  }
`;

export const CardMap = styled(Row)`
    height: 104px;
    width: 306px;

    .Col-ImagField {
        display: flex;
        justify-content: center;
        align-items: center;

      .ant-image {
          background: #f2f2f2;
          border-radius: 17px;
      }

      .ant-image-img {
          border-radius: 17px;
      }
    }
   
    .Col-infoCard {
        padding: 16px 25px 16px 0;
        display: grid;
        align-content: center;
        p {
          font-family: 'Roboto', sans-serif;
          margin-bottom: 0px;
        }
    }
    

    .Title-sede {
        font-weight: 500;
        font-size: 15px;
        color: #000000;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    
    .Inf-direction {
        font-weight: 400;
        font-size: 14px;
        color: #3742fa;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
`;

export const ContentDate = styled.div`
  .ant-skeleton-element {
    width: 100%;
  }
  .ant-picker-input > input {
    top: 8px;
    color: #1b1464;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    font-size: 14px;
    margin-left: 6px;
  }

  .Row-Content--timersvp {
    margin: 22px 42px 8px;
    font-family: 'Roboto', sans-serif;
    label {
      position: absolute;
      z-index: 10;
      font-size: 12px;
      top: 6px;
      left: 21px;
      font-weight: 500;
      color: #9daab8;
    }
  }

  .Col-title--timersvp {
    font-family: 'Roboto', sans-serif;
    p {
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 0px;
      line-height: 21px;
    }
  }

  .Row-ContentMap {
    margin: 8px 41px 13px;
    height: 300px;
    width: auto;
    box-shadow: 0px 14px 44px rgb(0 0 0 / 30%);
    border-radius: 20px;
    div:first-child {
      border-radius: 20px;
    }
    .ant-skeleton-button {
      width: 100%;
      border-radius: 20px;
      height: 280px;
    }

    .styleMarker {
        width: 20px;
        height: 20px;
        background: #ff1744;
        border-radius: 50%;
        border: 2px solid #ffffff;
        box-shadow: 0px 4px 34px #3742fa;
      }
  }

  @media (max-width: 992px) {
    .Row-ContentMap {
      display: none !important;
    }
    .ant-picker {
      width: 100%;
    }
  }

  @media (max-width: 608px) {
    .Col-title--timersvp {
      padding: 20px 0px;
      p { 
        color: #1b1464;
      }
  }
`;

export const AntdCard = styled(Card)`
  width: 100%;
  margin-top: 18px;
  margin-bottom: 18px;
  border: 0px;

  .image-gallery-thumbnail .image-gallery-thumbnail-image {
    border-radius: 10px;
  }

  // ********** slider Img********
  .image-gallery-image {
    height: 167px;
  }
  .image-gallery-thumbnails {
    padding: 14px 0 0 !important;
    border-radius: 10px;
  }

  .image-gallery-svg {
    width: 45px;
    height: 45px;
  }

  .image-gallery-slide .image-gallery-image {
    object-fit: cover;
    border-radius: 20px;
  }

  // ****************************
  .ant-card-body {
    padding: 0px;
  }

  .Col-infoCard {
    background: #ffff;
    padding-left: 20px;
    padding-top: 45px;
    p {
      font-family: 'Roboto', sans-serif;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .Title-field {
    font-weight: 500;
    font-size: 20px;
    color: #3742fa;
    margin-bottom: 0px;
  }

  .Type-field {
    font-weight: 500;
    font-size: 13px;
    color: #020609;
  }

  .Title-atrib {
    font-size: 18px;
    font-weight: 700;
    color: black;
    margin-bottom: 0px;
    margin-top: 27px;
  }

  .Title-sede {
    font-weight: 500;
    font-size: 20px;
    color: #1b1464;
    margin-bottom: 0px;
  }

  .Inf-direction {
    font-weight: 400;
    font-size: 16px;
    color: #616fa1;
    margin-bottom: 24px;
  }

  .Inf-field {
    margin-top: 10px;
    font-weight: 500;
    font-size: 16px;
    color: #020609;
    margin-bottom: 0px;
  }

  .div-tag {
    width: 90%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    .ant-tag {
      padding: 0px 5px;
      font-size: 12px;
      color: #616FA1; 
      margin-left: 0px;
      margin-right: 5px;
      height: 21px;    
    }
  }

  .ant-tag {
    color: #020609;
    margin-left: 15px;
    padding: 5px 15px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 12px;
  }

  .Col-PriceRsvp {
    text-align: right;
    p {
      font-family: 'Roboto', sans-serif;
    }
    .ant-btn {
      width: 117px;
      background: #00e676;
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
      font-size: 16px;
      color: #ffff;
      height: 44px;
      border: none;
      border-radius: 10px;
    }

    .ant-tag {
      margin: 10px 0 0;
      height: 34px;
      border-radius: 10px;
    }
  }

  .text-price {
    font-weight: 400;
    font-size: 12px;
    color: #616fa1;
    margin-bottom: 0px;
    margin-top: 28px;
  }

  .real-amount {
    font-size: 18px;
    color: #1B1464;
    font-weight: 500;
    text-decoration: line-through;
    margin-bottom: 0px;
  }

  .num-price {
    font-weight: 500;
    font-size: 26px;
    color: #616fa1;
    margin-bottom: 54px;
  }

  .amount {
    margin-bottom: 18px !important; //31px;
  }

  .cupon {
    margin-top: 6px //14px;
  }

  .ant-divider-inner-text {
    margin: 0px;
    padding: 0px;
  }

  label {
    position: absolute;
    z-index: 10;
    font-size: 12px;
    top: 6px;
    left: 12px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
  }

  .Col-Title--Info {
    padding-right: 15px;
  }
  
  .Col-content--infrsvp {
    p {
      margin-bottom: 0px;
    }
    .p-title {
      font-size: 14px;
      color: #9daab8;
      margin-top: 22px;
    }
    .p-info {
      font-size: 18px;
      color: #1b1464;
      font-weight: 500;
    }

    .Col-IcEdit {
      text-align: end;
    }
  }


  .Title-Sede {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 24px;
    color: #1e3799;
    margin: 0px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .Direction-Sede {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #616fa1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .Select-Inf--Center {
    .ant-select-selector {
      height: 45px;
      border-radius: 10px;
      padding-top: 14px;

      color: #1b1464;
      border-color: #dfe8f1;
      font-family: 'Roboto', sans-serif;
      font-size: 14px;
      font-weight: 600;
    }
  }

  @media (max-width: 1120px) { 
    .num-price {
      font-size: 22px !important;
      margin-bottom: 58px !important;
    }
  }

  @media (max-width: 992px) {
    
  }


  @media (max-width: 608px) {
    .Col-infoCard {
      padding: 0px !important;
    }

    .Inf-direction {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-bottom: 0px !important;
    }

    .Col-PriceRsvp {
      display: flex;
      justify-content: end;
      align-items: end;
    }
    .Col-ImagField {
      padding-top: 25px;
    }

    .image-gallery-slide .image-gallery-image {
      height: 220px;
      object-fit: initial;
      border-radius: 20px;
    }

    .image-gallery-bullets .image-gallery-bullets-container {
      position: relative;
      top: 45px;
    }
    .image-gallery-bullets .image-gallery-bullet {
      background: #b7d0db;
      box-shadow: none;
    }
    .image-gallery-bullets .image-gallery-bullet.active {
      background: #0000c4;
    }
    .div-tag {
      width: 100% !important;
      overflow-x: overlay;
    }
  
    .div-tag::-webkit-scrollbar {
      background: transparent;
    }
  }
  }
`;
