import React, { useState, useEffect } from 'react';
import { Button, Row, Col, Input, Select, Form, notification, Avatar, Popover } from 'antd';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import OtpInput from 'react-otp-input';
import 'react-phone-input-2/lib/style.css';
import Modal from './ModalS.style';
import LogoModal from '../../assets/ic-LogoModal.png';
import { ArrowRightOutlined, LoadingOutlined } from '@ant-design/icons';
import { getDocuments, getGeolocationViaIP } from '../../services/http';
import { getIdentityCookie, startPasswordless, verifyOTPcode, createUser, createIdentityCookie, logOut } from '../../services/auth';
import styled from 'styled-components';
import { useMediaQuery } from '../../shared/utils';

const { Option } = Select;

const isForSportCenter = !!process.env.REACT_APP_CENTER_KEY;

const InfProfile = styled.div`
  font-family: 'Roboto', sans-serif;
  line-height: 15px;

  .title {
    padding-left: 15px;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 0px;
    color: #c9d2dd;
  }

  .name {
    font-size: 16px;
    font-weight: 500;
    color: #1b1464;
  }

  .creditAmout {
    padding-left: 15px;
    font-size: 22px;
    font-weight: 500;
    color: #00e676;
    line-height: 23px;
  }

  .line {
    padding: 5px 0px;
    border-top: 1px solid #c9d2dd;
  }

  .linkProfile {
    color: #1b1464;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0px;
  }

  .linkProfileLogout {
    color: #ff1744;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0px;
  }
`;

const PopOverContent = (props) => {
  return (
    <InfProfile>
      {/*       <p className='title'>Hola!</p>
      <p className='name'>{userName}</p> */}
      <p className='title'>Crédito iCancha</p>
      <p className='creditAmout'>$0.00</p>
      <div className='line' />
      <Row>
        <Col span={12}>
          <Button type='link' className='linkProfile' onClick={() => props.handleClick()}>
            Mi Cuenta
          </Button>
        </Col>
        <Col span={12} style={{ textAlign: 'end' }}>
          <Button type='link' className='linkProfileLogout' onClick={() => logOut()}>
            Salir
          </Button>
        </Col>
      </Row>
    </InfProfile>
  );
};

const UserNumber = (props) => {
  const { nextStep, countryCode, addNumber } = props;
  const [errorSending, setErrorSending] = useState(false);
  const [processingReq, setProcessingReq] = useState(false);
  const onFinishFailed = (errorInfo) => {
    //console.log('Failed:', errorInfo);
  };
  const registerNumber = async (values) => {
    try {
      setErrorSending(false);
      setProcessingReq(true);
      const telefono = `+${values.phoneNumber}`;
      const request = await startPasswordless(telefono);
      //debugger;
      if (request) {
        addNumber(telefono);
        nextStep();
      } else {
        setErrorSending(true);
        setProcessingReq(false);
      }
    } catch (error) {
      setErrorSending(true);
      setProcessingReq(false);
    }
  };

  return (
    <>
      <Form name='formUserNumber' onFinish={registerNumber} onFinishFailed={onFinishFailed} layout='vertical'>
        <Row className='Row-ContentTitle'>
          <Col>
            <h1>
              Para continuar con tu ingreso <br />o registro indícanos tu número célular
            </h1>
            <p>enviaremos un SMS a tu número célular.</p>
          </Col>
        </Row>
        <Row className='Row-inputPhone'>
          <Col span={24}>
            <label>Tú Número Celular</label>
            <Form.Item
              name='phoneNumber'
              rules={[
                { required: true, message: '¡Por favor ingresa un número de teléfono!' },
                { min: 6, message: 'Debe tener mas de 6 caracteres' },
              ]}>
              <PhoneInput
                countryCodeEditable={false}
                country={`${countryCode}`}
                preferredCountries={['co', 'ec']}
                placeholder='Teléfono'
                defaultMask='(...) ...-.....'
                enableSearch
                disableSearchIcon
                searchPlaceholder='Buscar'
                inputClass='ant-input pInput'
                dropdownStyle={{ width: 'auto' }}
                inputStyle={{ width: '100%', border: '1px solid #d9d9d9', height: '58px', borderRadius: '10px', lineHeight: '21px', letterSpacing: 'normal' }}
              />
            </Form.Item>
            {errorSending && <p style={{ color: '#FF1744' }}>No se ha podido enviar el código al número indicado, por favor intenta de nuevo o utiliza otro número</p>}
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <p>Al ingresar estás aceptando los Términos y Condiciones de uso y Políticas de Privacidad de iCancha</p>
          </Col>
        </Row>
        <Row className='Row-Content--button'>
          {processingReq ? (
            <>
              <Col className='Col-text' span={20}>
                <p>Enviando</p>
              </Col>
              <Col className='Col-text' span={4}>
                <p>
                  <LoadingOutlined />
                </p>
              </Col>
            </>
          ) : (
            <>
              <Col className='Col-text' span={20}>
                <p>Siguiente</p>
              </Col>
              <Col span={4}>
                <Form.Item>
                  <Button type='primary' danger htmlType='submit'>
                    <ArrowRightOutlined />
                  </Button>
                </Form.Item>
              </Col>
            </>
          )}
        </Row>
      </Form>
    </>
  );
};

const ValidNumber = (props) => {
  const { nextStep, phoneNumber, hideModal, saveAuthData, setUser, modalRsvpCallBack } = props;
  const [code, setCode] = useState('');
  const [errorSending, setErrorSending] = useState(false);
  const [processingReq, setProcessingReq] = useState(false);

  const handleChange = (code) => {
    //console.log(code);
    setCode(code);
  };

  const onFinishFailed = (errorInfo) => {
    //console.log('Failed:', errorInfo);
  };

  const validar = async (values) => {
    try {
      setErrorSending(false);
      setProcessingReq(true);
      const request = await verifyOTPcode(values.codeValue, phoneNumber);
      //debugger;
      if (request.cod === 1000) {
        if (request.data.exists) {
          if (isForSportCenter) {
            hideModal();
            createIdentityCookie(request.data.authData.access_token, request.data.authData.id_token);
            modalRsvpCallBack(true);
          } else {
            //console.log({ request });
            const saveID = createIdentityCookie(request.data.authData.access_token, request.data.authData.id_token);
            //console.log({ saveID });
            setUser(saveID);
            hideModal();
          }
        } else {
          // El usuario no esta registrado en la BD
          saveAuthData(request.data.authData);
          /*

          {
    "validated": true,
    "exists": false,
    "authData": {
        "access_token": "LhE1d4sN3XKqXcXdjMbcWSO0c0bDkh3g",
        "id_token": "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6Ik1EQTFORUZCTmpNMU9ERTVSRVl3UkVJMlJqQkROVVk1UkRBeVJEQXpRemc0UmpGRlFUSkRNQSJ9.eyJodHRwczovL2FwaS1hdXRoLmljYW5jaGEuY29tL21ldGFkYXRhIjp7ImlkdXN1YXJpbyI6MTAwLCJudW1lcm9kb2N1bWVudG8iOjEyMzQ0LCJyb2xlaWQiOjQsInRpcG9kb2N1bWVudG8iOjJ9LCJnaXZlbl9uYW1lIjoiQWxiZXJ0byIsImZhbWlseV9uYW1lIjoiQXJpYXMiLCJuaWNrbmFtZSI6IiIsIm5hbWUiOiJBbGJlcnRvIEFyaWFzIiwicGljdHVyZSI6Imh0dHBzOi8vY2RuLmF1dGgwLmNvbS9hdmF0YXJzL2RlZmF1bHQucG5nIiwidXBkYXRlZF9hdCI6IjIwMjItMDItMjNUMTc6MjM6NDYuODk3WiIsInBob25lX251bWJlciI6Iis1NzMwMTM2NDEyMzEiLCJpc3MiOiJodHRwczovL2ljYW5jaGEtYXBwLmF1dGgwLmNvbS8iLCJzdWIiOiJzbXN8NjIwNTVlYWFkNjQ5NmZjZjY2NzUxNzVjIiwiYXVkIjoibjZRYmozYUdhakY0V0JWMkhYdVhRY1lGemJ5NlI1Y20iLCJpYXQiOjE2NDU2MzcwMjcsImV4cCI6MTY0NjI0MTgyN30.bQD3nDaLEs3GFqXGmCt0OGt5XRKzeKrAjQPcXoKFwUpmCEleAJAXBhxdDsZcohtDhBRB5eUOjwwidV94B4nXRgp8zydRGVTbmmsq6Ncmzn2biJlXmbzpjS0_gZNQaZYLEpnaLcIctly6b4VKuiDetWDNGCfQuevOjCcFBzhCciO_RrKF0mDKHyEj2lw0pkzv0uoAn34IWWVTp0fbgi76MdrJjKf_KFDx-AxXaWcpwMPmKdwdVb0oq6GyQW3oTDW-5iVyNUIS7qK-uwT8CngtyaGNyGt_qSCPvloGHGfFrHtGYQWMiilW8Ad8ECshxiFAG7jEqouHLVMe8iPENnMfdg",
        "scope": "openid profile email address phone",
        "expires_in": 86400,
        "token_type": "Bearer"
    }
}
*/
          nextStep();
        }
      } else {
        setErrorSending(true);
        setProcessingReq(false);
      }
    } catch (error) {
      setErrorSending(true);
      setProcessingReq(false);
      //console.log('validar', error);
    }
  };

  return (
    <>
      <Form name='formUserNumber' onFinish={validar} onFinishFailed={onFinishFailed} layout='vertical'>
        <Row>
          <Col span={24}>
            <h1>
              ¡Listo! Verifiquemos <br /> tu Código
            </h1>
            <p style={{ color: '#616FA1' }}>
              Hemos enviado un código de <br /> verificación a tu célular
            </p>
          </Col>
        </Row>
        <Row className='Row-ContetOtpInput'>
          <Col span={24}>
            <Form.Item
              name='codeValue'
              rules={[
                { required: true, message: '¡Por favor ingresa el codigo de validacion!' },
                { len: 6, message: '¡Por favor ingresa los 6 digitos!' },
              ]}>
              <OtpInput
                value={code}
                numInputs={6}
                onChange={handleChange}
                isInputNum={true}
                shouldAutoFocus={true}
                separator={<span>&nbsp;&nbsp;&nbsp;</span>}
                containerStyle={{
                  justifyContent: 'center',
                }}
                inputStyle={{
                  width: '48px',
                  height: '48px',
                  fontSize: '28px',
                  borderRadius: 10,
                  border: '1px solid #CFD3DB',
                  //color: 'black',
                }}
                focusStyle={{
                  width: '48px',
                  height: '48px',
                  border: '1px solid #1B1464',
                  outline: 'none',
                }}
              />
            </Form.Item>
          </Col>
          {errorSending && <p style={{ color: '#FF1744' }}>El código ingresado es incorrecto, recuerda que te enviamos el código al {phoneNumber}</p>}
        </Row>
        <Row>
          <Col span={24}>
            <p tyle={{ color: '#616FA1' }}>
              ¿No has recibido el código?
              <Button type='link' onClick={() => nextStep(true)}>
                Reenviar
              </Button>
            </p>
          </Col>
        </Row>
        <Row className='Row-Content--button'>
          {processingReq ? (
            <>
              <Col className='Col-text' span={20}>
                <p>Verificando</p>
              </Col>
              <Col className='Col-text' span={4}>
                <p>
                  <LoadingOutlined />
                </p>
              </Col>
            </>
          ) : (
            <>
              <Col className='Col-text' span={20}>
                <p>Validar</p>
              </Col>
              <Col span={4}>
                <Form.Item>
                  <Button type='primary' danger htmlType='submit'>
                    <ArrowRightOutlined />
                  </Button>
                </Form.Item>
              </Col>
            </>
          )}
        </Row>
      </Form>
    </>
  );
};

const UserForm = (props) => {
  const { countryCode, handleCancel, authData, setUser, modalRsvpCallBack } = props;
  const [documentos, setDocumentos] = useState([]);
  const [processingReq, setProcessingReq] = useState(false);
  const [errorSending, setErrorSending] = useState(false);

  const onFinishFailed = (errorInfo) => {
    //console.log('Failed:', errorInfo);
  };

  const registerFormData = async (values) => {
    try {
      const arrayNombreApellido = values.nombre.split(' ');

      const dataUser = {
        nombre: arrayNombreApellido[0],
        apellido: arrayNombreApellido[1],
        correo: values.correo,
        tipoDocumento: values.tipodedocumento,
        numeroDocumento: values.numerodocumento,
      };
      setErrorSending(false);
      setProcessingReq(true);
      const request = await createUser(authData.id_token, dataUser.nombre, dataUser.apellido, dataUser.tipoDocumento, dataUser.numeroDocumento, dataUser.correo);
      if (request.cod === 1000) {
        // se creo el usuario
        const initials = `${dataUser.nombre.charAt(0).toUpperCase()} ${dataUser.apellido.charAt(0).toUpperCase()}`;
        if (isForSportCenter) {
          createIdentityCookie(authData.access_token, authData.id_token, initials);
          handleCancel();
          modalRsvpCallBack(true);
        } else {
          createIdentityCookie(authData.access_token, authData.id_token, initials);
          setUser(initials);
          handleCancel();
        }
      } else {
        setErrorSending(true);
        // no se creo el usuario
      }
    } catch (error) {
      setProcessingReq(false);
      setErrorSending(true);
      //console.log('Error registerFormData', error);
    }
  };

  const validarNombre = async (_, value) => {
    if (value.split(' ').length < 2) {
      throw new Error('Debe contener Nombre y Apellido');
    }
    if (value.split(' ').length > 2) {
      throw new Error('Solo debes ingresar primer Nombre y Apellido');
    }
  };

  const resquestDocuments = async (country) => {
    try {
      //console.log('entro a co');
      const response = await getDocuments(country);
      const { cod, data } = response;
      if (cod === 1000) {
        setDocumentos(data);
      } else {
        throw new Error(`${cod}`);
      }
    } catch (error) {
      //console.log('error requestSports: ', error);
      notification.warning({
        message: 'No podemos hacer contacto con el servidor iCancha',
        description: 'Ocurrió un error intentando obtener el listado de documentos del servidor, si el problema persiste por favor contacta a soporte en info@icancha.com',
        duration: 3,
      });
    }
  };

  useEffect(() => {
    if (countryCode && countryCode === 'co') {
      ////console.log("entro a co")
      resquestDocuments(1);
    }
    if (countryCode && countryCode === 'ec') {
      resquestDocuments(2);
    }
  }, []);

  return (
    <>
      <Form name='formUserData' onFinish={registerFormData} onFinishFailed={onFinishFailed} layout='vertical'>
        <Row>
          <Col span={24}>
            <h1>
              Para finalizar tu registro <br /> confirma los siguientes datos
            </h1>
          </Col>
        </Row>
        <Row className='Row-Content--Formregister'>
          <Col span={24}>
            <label>Tú Nombre y Apellido</label>
            <Form.Item
              name='nombre'
              rules={[
                { required: true, message: '¡Por favor ingresa tu nombre y apellido!' },
                { validator: validarNombre },
                { min: 3, message: 'Debe tener mas de 3 caracteres ' },
                { max: 30, message: 'No debe tener mas de 20 caracteres' },
                { pattern: /^[a-zA-Z\sáÁéÉíÍóÓúÚüñÑ]+$/, message: 'No puede contener caracteres especiales y/o números' },
              ]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <label>Tipo de Documento</label>
            <Form.Item name='tipodedocumento' rules={[{ required: true, message: '¡Por favor ingresa un tipo de documento!' }]}>
              <Select notFoundContent='No hay datos'>
                {documentos.length > 0 &&
                  documentos.map((doc) => (
                    <Option key={doc.idtipodocumento} value={doc.idtipodocumento}>
                      {doc.nombre}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <label>Número de Documento</label>
            <Form.Item
              name='numerodocumento'
              rules={[
                { required: true, message: '¡Por favor ingresa un número de documento!' },
                { pattern: /^[0-9]*$/, message: 'Ingresar solo números' },
              ]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <label>Tú Email (Opcional)</label>
            <Form.Item name='correo' rules={[{ type: 'email', message: 'Debe ingresar un correo válido' }]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        {errorSending && <p style={{ color: '#FF1744' }}>No pudimos guardar en la base de datos la información por favor contacta con info@icancha.com para más información</p>}
        <Row className='Row-Content--button'>
          {processingReq ? (
            <>
              <Col className='Col-text' span={20}>
                <p>Guardando</p>
              </Col>
              <Col className='Col-text' span={4}>
                <p>
                  <LoadingOutlined />
                </p>
              </Col>
            </>
          ) : (
            <>
              <Col className='Col-text' span={20}>
                <p>Finalizar</p>
              </Col>
              <Col span={4}>
                <Form.Item>
                  <Button type='primary' danger htmlType='submit'>
                    <ArrowRightOutlined />
                  </Button>
                </Form.Item>
              </Col>
            </>
          )}
        </Row>
      </Form>
    </>
  );
};

const ModalS = (props) => {
  const { reserveButton, showModal, modalAuthCallBack, modalcenter, modalRsvpCallBack, closeMenu } = props;
  const [isUser, setIsUser] = useState(null);
  const [visible, setVisible] = useState(false);
  const [menuVisible, setmenuVisible] = useState(false);
  const [countryCode, setCountryCode] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [authData, setAuthData] = useState(null);
  const [step, setStep] = useState();
  const history = useHistory();
  const isDesktop = useMediaQuery('(min-width: 608px)');

  const addNumber = (value) => setPhoneNumber(value);

  const saveAuthData = (value) => setAuthData(value);

  const nextStep = (reset) => {
    if (reset) {
      setStep(1);
    } else {
      setStep((prevStep) => prevStep + 1);
    }
  };

  const handleCancel = () => {
    if (reserveButton) {
      modalAuthCallBack(false);
    }
    setVisible(false);
  };

  const resquestCountryCode = async () => {
    try {
      const request = await getGeolocationViaIP();
      if (request.result) {
        const { geodata } = request;
        //console.log({ geodata });
        const countryCode = geodata.countryCode.toLowerCase();
        setCountryCode(countryCode);
      }
    } catch (error) {
      //console.log('error al obtener codigo de pais');
    }
  };

  const handleClick = () => {
    history.push('/searcher/profile');
    setmenuVisible(false);
  };

  const onClose = () => {
    if (reserveButton) {
      modalAuthCallBack(false);
    }

    setVisible(false);
    setStep(1);
  };

  useEffect(() => {
    setStep(1);
    resquestCountryCode();
  }, []);

  useEffect(() => {
    const identity = getIdentityCookie();
    //console.log(identity);
    if (identity) {
      setIsUser(identity);
    }
    //console.log({ isDesktop });

  }, []);

  useEffect(() => {
    if (showModal) {
      setStep(1);
      setVisible(showModal);
    }
  }, [showModal]);

  return (
    <>
      {!reserveButton && (
        <>
          {isUser ? (
            isDesktop ? (
              <div style={{ textAlign: 'center' }}>
                <Popover
                  content={<PopOverContent handleClick={handleClick} />}
                  visible={menuVisible}
                  onVisibleChange={(visible) => setmenuVisible(visible)}
                  placement='bottomRight'
                  trigger='click'
                  overlayClassName='stylePopoverProfile'>
                  <Avatar style={{ backgroundColor: '#3742FA', verticalAlign: 'middle', fontWeight: 'bold' }} size={{ xs: 50, sm: 50, md: 50, lg: 50, xl: 50, xxl: 50 }}>
                    {isUser}
                  </Avatar>
                </Popover>
              </div>
            ) : (
              <div
                style={{
                  display: 'content',
                  //justifyContent: 'center',
                  //alignItems: 'left',
                  paddingBottom: '10px',
                }}>
                <Button type='link' style={{ color: 'white', fontSize: '16px', width: '50%', textAlign: 'left', padding: '0px' }}>
                  <Link to={{ pathname: `searcher/profile` }}>Mi cuenta</Link>
                </Button>
                {/*<Divider type='vertical' />*/}
                <Button type='link' style={{ color: '#ff1744', fontSize: '16px', width: '50%', textAlign: 'right', padding: '0px' }} onClick={() => logOut()}>
                  Salir
                </Button>
              </div>
            )
          ) : (
            <Button
              type='primary'
              className='Button-login'
              onClick={() => {
                setVisible(true);
                if (!isDesktop) {
                  closeMenu();
                }
              }}>
              Ingresa / Registrate
            </Button>
          )}
        </>
      )}

      <Modal centered visible={visible} closable={false} footer={null} maskStyle={{ background: 'rgba(4, 0, 47, 0.86)' }} zIndex={1040} onCancel={() => onClose()} width={438}>
        <Row className='Row-logoContent'>
          {!modalcenter && (
            <Col>
              <div>
                <img src={LogoModal} alt='logo' style={{}} />
              </div>
            </Col>
          )}
        </Row>
        {step === 1 && <UserNumber step={step} nextStep={nextStep} countryCode={countryCode} addNumber={addNumber} />}
        {step === 2 && (
          <ValidNumber step={step} nextStep={nextStep} phoneNumber={phoneNumber} hideModal={handleCancel} saveAuthData={saveAuthData} setUser={setIsUser} modalRsvpCallBack={modalRsvpCallBack} />
        )}
        {step === 3 && <UserForm countryCode={countryCode} handleCancel={handleCancel} authData={authData} setUser={setIsUser} modalRsvpCallBack={modalRsvpCallBack} />}
      </Modal>
    </>
  );
};

export default ModalS;
