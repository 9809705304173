import { lazy } from 'react';
import Home from './pages/Home/Home';
import HomeCenter from 'pages/HomeCenter/HomeCenter';
import SearchAllFields from './pages/SearchAllFields';


const isForSportCenter = !!process.env.REACT_APP_CENTER_KEY;

const LANDING_ROUTES = [
  {
    path: '/',
    exact: true,
    component: isForSportCenter ? HomeCenter : Home,
  },
];

const InnerRoutes = [
  {
    path: '',
    exact: true,
    component: SearchAllFields,
  },
  {
    path: 'profile',
    exact: true,
    component: lazy(() => import('./pages/Profile')),
  },
  {
    path: 'fields/:fieldId',
    exact: true,
    component: lazy(() => import('./pages/DetailsField')),
  },
  {
    path: 'sportscenter',
    exact: true,
    component: lazy(() => import('./pages/SportCenters')),
  },
  {
    path: 'sportscenter/:sportcenterId',
    exact: true,
    component: lazy(() => import('./pages/DetailsCenter')),
  },
];

export { LANDING_ROUTES, InnerRoutes };
