import styled from 'styled-components';

const ComponentTimeGrid = styled.div`
  .Row-title-dataTime {
    justify-content: space-between;
    .ant-col {
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }
    p {
      font-family: 'Roboto', sans-serif;
      font-weight: 500;
      margin-bottom: 0px;
      margin-left: 8px;
    }
  }

  .Row-Content--dataTime {
    padding: 11px 41px 20px;
  }

  .Row-grid {
    margin-top: 10px;
    justify-content: space-between;
  }

  .hour-slot {
    width: 157px;
    height: 38px;
    border-radius: 10px;
    border: 1px solid #a5abb0;
    color: #a5abb0;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .hour-slot > button {
    font-weight: bold;
    border: 0px;
  }

  .hour-available {
    background: #ffff;
    border: 1px solid #ffffff;
    box-shadow: 0px 4.5px 30px rgba(169, 179, 185, 0.1);
  }

  .hour-morning {
    color: #ff9e44;
  }

  .hour-morning > button {
    color: #ff9e44;
  }

  .hour-afternoon {
    color: #ff7425;
  }

  .hour-afternoon > button {
    color: #ff7425;
  }

  .hour-night {
    color: #8a2be2;
  }

  .hour-night > button {
    color: #8a2be2;
  }

  .hour-selected {
    background: #00e676;
    box-shadow: 0px 4.50725px 13.5217px rgba(0, 230, 118, 0.5);
    border: 1px solid #00e676;
    color: #ffffff;
  }

  .hour-selected > button {
    color: #ffffff;
  }

  .hour-disabled {
    color: #a5abb0;
  }

  .hour-disabled > button {
    color: #a5abb0;
  }
`;
export default ComponentTimeGrid;
