import React, { useState } from 'react';
import { bubble as Menu } from 'react-burger-menu';
import { Link } from 'react-router-dom';
import ModalS from 'components/UI/ModalS';

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleStateChange = (state) => setMenuOpen(state.isOpen);

  const closeMenu = () => setMenuOpen(false);

  return (
    // Pass on our props
    <Menu isOpen={menuOpen} onStateChange={handleStateChange} {...props}>
      <Link className='menu-item' to='/searcher' onClick={() => closeMenu()}>
        Todas las Canchas
      </Link>

      <Link className='menu-item' to='/searcher/sportscenter' onClick={() => closeMenu()}>
        Centro Deportivos
      </Link>

      <ModalS closeMenu={closeMenu} />

      <small>Versión: {process.env.REACT_APP_VERSION}</small>
    </Menu>
  );
};
