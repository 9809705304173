import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Card, Image, Tag, Skeleton } from 'antd';
import styled from 'styled-components';
//import DetailsField from 'pages/DetailsField';
import { Link } from 'react-router-dom';
import { useMediaQuery } from '../../shared/utils';

const AntdCard = styled(Card)`
  width: 100%;
  margin-top: 30px;
  border: 0px;

  .ant-card-body {
    padding: 0px;
  }

  .Col-ImagField {
    display: flex;
    justify-content: center;
    img {
      border-radius: 10px;
      object-fit: cover;
    }
    .ant-skeleton-element {
      width: 100%;
    }

    .ant-skeleton-image {
      width: auto;
    }
  }

  .Col-infoCard {
    line-height: initial;
    background: #ffff;
    padding-left: 20px;
    align-content: center;
    display: grid;
    p {
      font-family: 'Roboto', sans-serif;
      white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    }
  }


  .Title-field {
    font-weight: 500;
    font-size: 20px;
    color: #3742fa;
    margin-bottom: 0px;
  }

  .Title-sede {
    font-weight: 500;
    font-size: 20px;
    color: #1b1464;
    margin-bottom: 0px;
  }

  .Inf-direction {
    font-weight: 400;
    font-size: 16px;
    color: #616fa1;
    margin-bottom: 0px;
  }

  .Inf-distance {
    font-weight: 500;
    font-size: 13px;
    color: #020609;
    margin-bottom: 0px;
    .ant-tag {
      margin: 0px;
      font-weight: 400;
      font-size: 12px;
      color: #616fa1;
    }
  }

  .Inf-field {
    font-weight: 500;
    font-size: 13px;
    color: #020609;
    margin-bottom: 0px;
  }

  .ant-tag {
    color: #020609;
    margin-left: 15px;
    padding: 5px 15px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 12px;
    border-radius: 10px;
  }

  .Col-PriceRsvp {
    line-height: initial;
    text-align: right;
    p {
      font-family: 'Roboto', sans-serif;
    }
  }

  .text-price {
    font-weight: 400;
    font-size: 12px;
    color: #616fa1;
    margin-bottom: 0px;
  }

  .num-price {
    font-weight: 500;
    font-size: 26px;
    color: #616fa1;
    margin-bottom: 58px;
  }

  .ant-btn {
    width: 117px;
    background: #00e676;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #ffff;
    height: 44px;
    border: none;
    border-radius: 10px;
  }

  @media (max-width: 609px) {
    margin-top: 10px !important;
    border-radius: 10px;
    .ant-card-body {
      padding: 10px;
    }

     {
      .ant-image {
        height: 132px !important;
      }
      img {
        height: 100% !important;
      }
    }
    .Col-infoCard {
      display: inline-grid;
      align-content: center;

      .Title-field {
        font-size: 14px;
      }
      .Title-sede {
        font-size: 14px;
      }
      .Inf-direction {
        font-size: 12px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .Inf-distance {
        font-size: 12px;
        .ant-tag {
          font-size: 12px;
        }
      }

      .text-price {
        color: #1b1464 !important;
      }
      .num-price {
        margin-bottom: 0px;
      }
    }
  }
`;

const CardField = ({ fieldData, loading, timeValues }) => {
  const isDesktop = useMediaQuery('(min-width: 609px)');
  const [dateTime, setDateTime] = useState(null);

  useEffect(() => {
    if (timeValues) {
      const getTimeValues = timeValues();
      setDateTime({
        date: getTimeValues.date,
        time: getTimeValues.time,
      });
    }
  }, [timeValues]);

  return (
    <AntdCard>
      {isDesktop ? (
        <>
          <Row>
            <Col span={6} className='Col-ImagField'>
              {loading ? (
                <>
                  <Skeleton.Image style={{ height: '160px', marginBottom: '10px' }} />
                </>
              ) : (
                <>
                  {/*<div style={{ background: '#FFDF00', width: '70px', height: '39px', position: 'absolute', zIndex: '2', display: 'flex', justifyContent: 'center', borderRadius: '0px 10px' }}>
                     <p style={{ color: '#1B1464', fontFamily: '"Roboto",  sans-serif', fontSize: '14px', fontWeight: '700', display: 'grid', alignContent: 'center', margin: '0px' }}>20% DTO</p>
                   </div>*/}
                  <Image height={160} preview={false} src={fieldData.imagen} />
                </>
              )}
            </Col>
            <Col span={11} className='Col-infoCard'>
              {loading ? (
                <>
                  <Skeleton active='true' paragraph={{ rows: 3 }} />
                </>
              ) : (
                <>
                  <p className='Title-field'>{fieldData.nombrecancha}</p>
                  <p className='Title-sede'>{fieldData.nombresede}</p>
                  <p className='Inf-direction'>{fieldData.direccion}</p>
                  <p className='Inf-distance'>
                    Distancia <Tag color='transparent'>{`${fieldData.distance.toFixed(2)} km`}</Tag>
                  </p>
                  <p className='Inf-field'>
                    Tipo de Cancha <Tag color='#FBB800'>{fieldData.clasificacionsiglas}</Tag>
                  </p>
                </>
              )}
            </Col>
            <Col span={7} className='Col-PriceRsvp'>
              {loading ? (
                <>
                  <div style={{ padding: '10px' }}>
                    <Skeleton active='true' paragraph={{ rows: 1 }} style={{ textAlign: 'right' }} />
                    <Skeleton.Button active='true' style={{ height: '54px' }} />
                  </div>
                </>
              ) : (
                <>
                  <p className='text-price'>Valor por Hora</p>
                  <p className='num-price'>{`$ ${fieldData.monto && fieldData.monto.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}</p>
                  <Button type='Primary'>
                    <Link to={{ pathname: `searcher/fields/${fieldData.idcancha}`, state: { fieldData, dateTime } }}>Ver Detalle</Link>
                  </Button>
                </>
              )}
            </Col>
          </Row>
        </>
      ) : (
        <>
          {loading ? (
            <>
              <Row>
                <Col span={10} className='Col-ImagField'>
                  <Skeleton.Image style={{ height: '160px', marginBottom: '10px' }} />
                </Col>
                <Col span={14} className='Col-infoCard'>
                  <Skeleton active='true' paragraph={{ rows: 3 }} />
                </Col>
              </Row>
            </>
          ) : (
            <>
              <Link to={{ pathname: `searcher/fields/${fieldData.idcancha}`, state: { fieldData, dateTime } }}>
                <Row>
                  {/*  <Col span={24} style={{ display: 'flex', justifyContent: 'end' }}>
                <div
                  style={{
                    background: '#FFDF00',
                    width: '53px',
                    height: '29px',
                    position: 'absolute',
                    zIndex: '2',
                    display: 'flex',
                    justifyContent: 'center',
                    borderRadius: '0px 10px',
                    top: '-10px',
                    right: '-10px',
                  }}>
                   <p style={{ color: '#1B1464', fontFamily: '"Roboto",  sans-serif', fontSize: '10px', fontWeight: '700', display: 'grid', alignContent: 'center', margin: '0px' }}>20% DTO</p>
                </div>
              </Col>*/}
                  <Col span={10} className='Col-ImagField'>
                    <Image height={160} preview={false} src={fieldData.imagen} />
                    <div
                      style={{
                        background: '#FBB800',
                        width: '60px',
                        height: '23px',
                        position: 'absolute',
                        zIndex: '2',
                        display: 'flex',
                        justifyContent: 'center',
                        borderRadius: '6px',
                        top: '103px',
                        right: '10px',
                      }}>
                      <p style={{ color: '#1B1464', fontFamily: '"Roboto",  sans-serif', fontSize: '10px', fontWeight: '700', display: 'grid', alignContent: 'center', margin: '0px' }}>
                        {fieldData.clasificacionsiglas}
                      </p>
                    </div>
                  </Col>
                  <Col span={14} className='Col-infoCard'>
                    <p className='Title-field'>{fieldData.nombrecancha}</p>
                    <p className='Title-sede'>{fieldData.nombresede}</p>
                    <p className='Inf-direction'>{fieldData.direccion}</p>
                    <p className='Inf-distance'>
                      Distancia <Tag color='transparent'>{`${fieldData.distance.toFixed(2)} km`}</Tag>
                    </p>
                    <p className='text-price'>Valor por hora</p>
                    <p className='num-price'>{`$ ${fieldData.monto && fieldData.monto.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}</p>
                  </Col>
                </Row>
              </Link>
            </>
          )}
        </>
      )}
    </AntdCard>
  );
};

export default CardField;
