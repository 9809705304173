import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import es from 'antd/lib/locale/es_ES';
import 'moment/locale/es';

import { Button, Row, Col, Select, DatePicker, TimePicker, ConfigProvider, notification, Form } from 'antd';
import { EffectFade, Navigation, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import 'swiper/swiper.min.css';
import 'swiper/swiper-bundle.min.css';
// Styles must use direct files imports
// Import Swiper styles
/* import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar'; */
// Pagination module

/* import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination'; */

/*import imgBasketball from '../../assets/img-basketball.jpg';
import imgSoccer from '../../assets/img-soccer.jpg';
import imgTennis from '../../assets/img-tennis.jpg';*/

import imgBasketball from '../../assets/Frame10.jpg';
import imgSoccer from '../../assets/Frame12.jpg';
import imgTennis from '../../assets/Frame11.jpg';

import IconDate from '../../assets/ic-icon-date.png';
import IconField from '../../assets/ic-icon-field.png';
import IconPay from '../../assets/ic-icon-pay.png';
import PreviewRsvp from '../../assets/ic-PreviewRsvp.png';
import Viewmovil from '../../assets/ic-ViewMovil.png';
import googleplay from '../../assets/ic-googleplay.png';
import appstore from '../../assets/ic-appstore.png';
import Header from '../../components/Layout/Header';
import Footer from '../../components/Layout/Footer';
import { Content, Layout } from './Home.styles';
import { getCountries, getCities, getSports, getGeolocationViaIP } from './../../services/http';
import { useMediaQuery } from '../../shared/utils';
import Sidebar from '../../components/Layout/Sidebar';

moment.locale('es');
const dateFormat = 'DD MMM YYYY';

const { Option } = Select;

const Home = () => {
  const today = moment().format('DD MMM YYYY');
  const allHours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];

  const isDesktop = useMediaQuery('(min-width: 609px)');
  const [selectedDate, setselectedDate] = useState(today);
  const [location, setLocation] = useState({});
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [sports, setSports] = useState([]);

  const [form] = Form.useForm();
  const history = useHistory();

  const updateSelectedDate = (selected) => {
    setselectedDate(selected.format('DD MMM YYYY'));
  };

  const disabledDate = (current) => {
    // Deshabilitar los días pasados al actual
    return current && current < moment(today, 'DD MMM YYYY');
  };

  const disabledHours = (current) => {
    let blockedhours = [0, 1, 2, 3, 4, 5, 6];
    if (moment(today, 'DD MMM YYYY') >= moment(selectedDate, 'DD MMM YYYY')) {
      const actualHour = parseInt(moment().format('HH'), 10);
      blockedhours = allHours.filter((hora) => hora <= actualHour);
    }
    return blockedhours;
  };

  const defaultTime = () => {
    const datetime = moment(Date.now());
    if (datetime.get('minute') > 30) {
      return moment(datetime.set('hour', datetime.get('hour') + 1).set('minute', 0), 'HH:mm');
    } else {
      // return moment(datetime.set('minute', 30), 'HH:mm');
      return moment(datetime.set('hour', datetime.get('hour') + 1).set('minute', 0), 'HH:mm');
    }
  };
  const initialDateTime = {
    date: moment(selectedDate, dateFormat),
    time: defaultTime(),
  };

  const checkGeolocPermissions = async () => {
    try {
      const request = await getGeolocationViaIP();
      if (request.result) {
        const { geodata } = request;
        //console.log({ geodata });
        setLocation((prev) => ({
          latitude: geodata.latitude,
          longitude: geodata.longitude,
          country: geodata.country,
          city: geodata.city,
          code: geodata.countryCode,
        }));
        notification.success({
          message: 'Estamos utilizando tu ubicación aproximada',
          description: 'Utilizamos tu ubicación aproximada para poder brindarte mejores resultados de búsqueda',
          duration: 3,
        });
      }
    } catch (error) {
      notification.warning({
        message: 'No podemos acceder a tu ubicación',
        description: 'Ocurrió un error intentando ubicar tu ubicación de forma aproximada, si el problema persiste por favor contacta a soporte en info@icancha.com',
        duration: 3,
      });
    }
  };

  const requestCountries = async () => {
    try {
      const response = await getCountries();
      ////console.log({ response });
      const { cod, data } = response;
      if (cod === 1000) {
        setCountries(data);
        if (location.country) {
          console.log({ location });
          const geoCountry = location.country.toLowerCase();
          const isCountryCovered = data.filter((country) => country.nombre.toLowerCase().includes(geoCountry));
          if (isCountryCovered.length >= 1) {
            form.setFieldsValue({ country: isCountryCovered[0].idpais });
            requestCities(isCountryCovered[0].idpais);
          }
        }
      } else {
        throw new Error(`${cod}`);
      }
    } catch (error) {
      //console.log('error getCountries: ', error);
      notification.warning({
        message: 'No podemos hacer contacto con el servidor iCancha',
        description: 'Ocurrió un error intentando obtener el listado de paises del servidor, si el problema persiste por favor contacta a soporte en info@icancha.com',
        duration: 3,
      });
    }
  };

  const requestCities = async (idPais) => {
    try {
      const response = await getCities(idPais);
      ////console.log({ response });
      const { cod, data } = response;
      if (cod === 1000) {
        setCities(data);
        if (location.city) {
          const geoCity = location.city.toLowerCase();
          const isCityCovered = data.filter((city) => city.nombre.toLowerCase().includes(geoCity));
          if (isCityCovered.length >= 1) {
            form.setFieldsValue({ city: isCityCovered[0].idciudad });
          }
        } else {
          form.setFieldsValue({ city: data[0].idciudad });
        }
      } else {
        throw new Error(`${cod}`);
      }
    } catch (error) {
      //console.log('error requestCities: ', error);
      notification.warning({
        message: 'No podemos hacer contacto con el servidor iCancha',
        description: 'Ocurrió un error intentando obtener el listado de ciudades del servidor, si el problema persiste por favor contacta a soporte en info@icancha.com',
        duration: 3,
      });
    }
  };

  const requestSports = async () => {
    try {
      const response = await getSports();
      // //console.log({ response });
      const { cod, data } = response;
      if (cod === 1000) {
        setSports(data);
      } else {
        throw new Error(`${cod}`);
      }
    } catch (error) {
      //console.log('error requestSports: ', error);
      notification.warning({
        message: 'No podemos hacer contacto con el servidor iCancha',
        description: 'Ocurrió un error intentando obtener el listado de deportes del servidor, si el problema persiste por favor contacta a soporte en info@icancha.com',
        duration: 3,
      });
    }
  };

  useEffect(() => {
    checkGeolocPermissions();
  }, []);

  useEffect(() => {
    requestCountries();
    requestSports();
  }, [location]);

  const onFinish = (values) => {
    ////console.log({ values });
    const preparedTime = values.date.set('hour', values.time.get('hour')).set('minute', values.time.get('minute'));
    const searchParams = {
      date: values.date.format('YYYY-MM-DD'),
      time: preparedTime.set('second', 0).unix(),
      sport: values.sport,
      latitude: cities.filter((city) => city.idciudad === values.city)[0].latitud,
      longitude: cities.filter((city) => city.idciudad === values.city)[0].longitud,
      country: countries.filter((country) => country.idpais === values.country)[0].iso,
    };
    history.push('/searcher', { searchParams, sports });
  };

  const onFinishFailed = (errorInfo) => {
    //console.log('Failed:', errorInfo);
  };

  return (
    <ConfigProvider locale={es}>
      <Layout>
        {!isDesktop && (
          <>
            <Sidebar pageWrapId={'page-wrap'} right />
          </>
        )}
        <Header />
        <Content
          style={{
            background: 'linear-gradient(#DDE6EE 0%, #ECF0F4 100%)',
          }}>
          <div style={{ maxWidth: '1440px', margin: 'auto' }}>
            <div id='Home' />
            <Row className='Row-Contentbg'>
              <Col className='Col-contentImg' span={24}>
                {/* <Row>
                  <Col span={24}>
                    <img src={Player} alt='player imagen' /> */}
                <Swiper
                  spaceBetween={30}
                  effect={'fade'}
                  navigation={false}

                  autoplay={{
                    delay: 2000,
                    disableOnInteraction: false,
                  }}
                  modules={[EffectFade, Navigation, Pagination, Autoplay]}
                  className='mySwiper'>
                  <SwiperSlide>
                    <img src={imgBasketball} alt="imgBasketball" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src={imgSoccer} alt="imgSoccer" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src={imgTennis} alt="imgTennis" />
                  </SwiperSlide>
                </Swiper>
                {/*}  </Col>
                </Row>*/}
              </Col>

              <Col className='Col-menu--content'>
                {/* menu landding */}
                <Form form={form} name='landingSearch' onFinish={onFinish} onFinishFailed={onFinishFailed} layout='horizontal'>
                  <Row className='Row-Select--menu' gutter={[16, 16]}>
                    <Col className='Col-Select--Title'>
                      <h1>
                        Encuentra aquí<br />
                        la cancha de tu deporte<br />
                        favorito y realiza tu<br />
                        reserva en línea
                      </h1>
                    </Col>
                    <Col className='Col-first--SelectButtom Col-content--select'>
                      <Form.Item name='country' help='' rules={[{ required: true }]}>
                        <Select
                          className='firstSelect'
                          placeholder='Selecciona el país'
                          style={{
                            width: '202px',
                            minWidth: '50%',
                            borderRadius: '10px',
                          }}
                          showArrow={false}
                          onChange={requestCities}>
                          {countries.length > 0 &&
                            countries.map((country) => (
                              <Option key={country.idpais} value={country.idpais}>
                                {country.nombre}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col className='Col-content--select'>
                      <Form.Item name='city' help='' rules={[{ required: true }]}>
                        <Select notFoundContent='No hay datos' className='selectMenu' placeholder='Ciudad' showArrow={false}>
                          {cities.length > 0 &&
                            cities.map((city) => (
                              <Option key={city.idciudad} value={city.idciudad}>
                                {city.nombre}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col className='Col-content--select'>
                      <Form.Item name='sport' help='' rules={[{ required: true }]}>
                        <Select notFoundContent='No hay datos' className='selectMenu' placeholder='Deporte' showArrow={false}>
                          {sports.length > 0 &&
                            sports.map((sport) => (
                              <Option key={sport.iddeporte} value={sport.iddeporte}>
                                {sport.nombre}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col className='Col-content--select'>
                      <Form.Item name='date' help='' rules={[{ required: true }]} initialValue={initialDateTime.date}>
                        <DatePicker inputReadOnly placeholder='Fecha' allowClear={false} format={dateFormat} disabledDate={disabledDate} onChange={updateSelectedDate} />
                      </Form.Item>
                    </Col>
                    <Col className='Col-content--select'>
                      <Form.Item name='time' help='' rules={[{ required: true }]} initialValue={initialDateTime.time}>
                        <TimePicker hideDisabledOptions inputReadOnly showNow='false' placeholder='Hora' allowClear={false} minuteStep={60} format='HH:mm' disabledHours={disabledHours} />
                      </Form.Item>
                    </Col>
                    <Col className='Col-first--SelectButtom Col-content--select'>
                      <Form.Item>
                        <Button className='buttonSearch' type='primary' htmlType='submit'>
                          Buscar
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
            {/**************************** Segundo Row ******************************/}
            <Row className='Row-titleIcons'>
              <Col className='Col-titleIcons' span={24}>
                <p>Deja de dar vueltas y</p>
                <p className='textTitle'>reserva en solo 3 pasos</p>
              </Col>
            </Row>
            <Row
              style={{
                paddingTop: '60px',
                paddingBottom: '130px',
                justifyContent: 'center',
                maxWidth: '1440px',
                margin: 'auto',
              }}>
              <Col span={20}>
                {/*xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}*/}
                <Row>
                  <Col style={{ textAlign: 'center' }} xs={24} sm={12} md={8} lg={8} xl={8} xxl={8}>
                    <img src={IconDate} style={{ paddingLeft: '20%' }} alt='icon date' />
                    <p
                      style={{
                        fontSize: '26px',
                        fontWeight: '700',
                        textAlign: 'center',
                        fontFamily: '"Rubik", sans-serif',
                      }}>
                      Selecciona
                      <br />
                      fecha y hora
                    </p>
                  </Col>
                  <Col style={{ textAlign: 'center' }} xs={24} sm={12} md={8} lg={8} xl={8} xxl={8}>
                    <img src={IconField} style={{ paddingLeft: '20%' }} alt='icon field' />
                    <p
                      style={{
                        fontSize: '26px',
                        fontWeight: '700',
                        textAlign: 'center',
                        fontFamily: '"Rubik", sans-serif',
                      }}>
                      Elige la cancha que
                      <br />
                      prefieras
                    </p>
                  </Col>
                  <Col style={{ textAlign: 'center' }} xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                    <img src={IconPay} style={{ paddingLeft: '20%' }} alt='icon pay' />
                    <p
                      style={{
                        fontSize: '26px',
                        fontWeight: '700',
                        textAlign: 'center',
                        fontFamily: '"Rubik", sans-serif',
                      }}>
                      Realiza el pago
                      <br />
                      en línea
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
            {/**************************** Tercer Row ******************************/}
            <Row className='Row-infoic'>
              <div id='iCancha' />
              {/*cambiar la height para trabajar a view desde md a xs*/}
              <Col
                className='Col-infoic--img'
                xs={24}
                sm={24}
                md={24} //hacer query
                lg={14} //prediseñada fija 992px
                xl={14} //prdiseñada fija 1140px
                xxl={14}>
                <img src={PreviewRsvp} alt='PreviewRsvp' style={{ marginTop: '-106px', width: '622px', minWidth: '50%' }} />
              </Col>
              <Col
                className='Col-infoic--icancha'
                xs={24}
                sm={24}
                md={24} // hacer query
                lg={10} //prediseñada fija 992px
                xl={10} //prdiseñada fija 1140px
                xxl={10}>
                <Row style={{ justifyContent: 'center' }}>
                  <Col span={20}>
                    <h1>iCancha</h1>
                    <h2>Digitaliza y automatiza los procesos de tu Centro Deportivo de la manera más fácil, ágil y económica.</h2>
                    <p>
                      Con iCancha tienes acceso a <strong>SARi</strong>, nuestro Sistema de Administración de Reservas, con el que puede conectar nuestros diferentes productos, que te ayudan en el
                      proceso de aumentar tus ventas y agilizar tu operatividad.Con iCancha puedes tener tu propio eCommerce y Pasarela de Pagos, además de estar conectado a nuestra aplicación móvil y
                      web para ampliar tus canales de ventas
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
            {/**************************** Cuarto Row ******************************/}
            <Row className='Row-info'>
              <div id='Centro-Deportivo' />
              <Col className='Col-info--content'>
                <Row className='Row--info--content-text'>
                  <Col span={24}>
                    <h1>
                      Si eres un Centro Deportivo
                      <br />y deseas más información
                    </h1>
                  </Col>
                  <Col span={24}>
                    <p>Escribenos en whatsapp, un asesor estará contigo</p>
                  </Col>
                </Row>
                <Row style={{ justifyContent: 'center' }}>
                  <Col
                    style={{
                      textAlign: 'center',
                      position: 'absolute',
                      marginTop: '36px',
                    }}
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    xxl={24}>
                    <Button
                      type='primary'
                      className='buttonws'
                      target='_blank'
                      href={location.countryCode === 'EC' ? `https://wa.me/593997220556?text=Hola Equipo iCancha!` : `https://wa.me/573157708440?text=Hola Equipo iCancha!`}>
                      WhatsApp
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
            {/**************************** Quinto Row ******************************/}
            <Row className='Row-infoapp'>
              <Col xs={24} sm={24} md={24} lg={12} xl={13} xxl={13}>
                <Row className='Row-infoapp--contenttext'>
                  <Col className='Col-infoapp--text' span={20}>
                    <h1>Descarga iCancha en tu celular</h1>
                    <p>+ de 10K de usuarios ya reservan su cancha en línea</p>
                  </Col>
                  <Col span={20} style={{ display: 'flex' }}>
                    <Button type='link' style={{ display: 'flex', minWidth: '100px' }}>
                      <img src={googleplay} alt='icon googleplay' style={{ minWidth: '100px' }} />
                    </Button>
                    <Button type='link' style={{ display: 'flex', minWidth: '100px' }}>
                      <img src={appstore} alt='icon appstore' style={{ minWidth: '100px' }} />
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col
                className='Col-infoapp-image'
                xs={24}
                sm={24}
                md={24}
                lg={12} //fijado view 992px
                xl={11} //fijado view
                xxl={11}>
                <img src={Viewmovil} alt='icon movil' style={{ marginTop: '-31px', height: '722px', minHeight: '50%' }} />
              </Col>
            </Row>
          </div>
          {/**************************** Primer Row ******************************/}
        </Content>
        <Footer countries={location.countryCode} />
      </Layout>
    </ConfigProvider>
  );
};

export default Home;
