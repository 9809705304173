import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Row, Col, Button, Skeleton } from 'antd';
import moment from 'moment';
import { ReactComponent as IcSun } from '../../assets/ic-sun.svg';
import { ReactComponent as IcSunsent } from '../../assets/ic-sunset.svg';
import { ReactComponent as IcMoon } from '../../assets/ic-moon.svg';
import ComponentTimeGrid from './TimeGrid.style';

const TimeGrid = (props) => {
  const { preSelectData, fieldTimeGrid, manageSelection, currentDate } = props;

  const [gridData, setGridData] = useState([]);
  const [selectedTime, setSelectedTime] = useState({});

  const hourClicked = (hourIndex) => {
    const hourInfo = gridData[hourIndex];
    if (hourInfo.selected) {
      const updatedGridData = gridData.map((time) => {
        return {
          ...time,
          selected: false,
        };
      });
      setGridData(updatedGridData);
      setSelectedTime(null);
      manageSelection(null, null, null);
    } else {
      if (selectedTime && Object.keys(selectedTime).length > 0) {
        const { selectedTimeStart, selectedTimeEnd } = selectedTime;
        const startHour = selectedTimeStart.get('hour');
        const parsedSelectedHour = parseTime(hourInfo.hora);
        const parsedSelectedTimeStart = moment(selectedTimeStart);
        const parsedSelectedTimeEnd = moment(selectedTimeEnd);

        const selectedHour = parsedSelectedHour.get('hour');
        if (selectedHour > startHour) {
          const isSame = parsedSelectedTimeEnd.unix() === parsedSelectedHour.unix();
          if (isSame) {
            const updatedGridData = gridData.map((time) => {
              if (time.hora === hourInfo.hora) {
                return {
                  ...time,
                  selected: true,
                };
              } else {
                return { ...time };
              }
            });
            setGridData(updatedGridData);
            const newEndHour = parseEndTime(hourInfo.hora);
            setSelectedTime((prev) => ({
              ...prev,
              selectedTimeEnd: newEndHour,
              monto: parseFloat(prev.monto) + parseFloat(hourInfo.monto),
            }));
          } else {
            const updatedGridData = gridData.map((time) => {
              if (time.hora === hourInfo.hora) {
                return {
                  ...time,
                  selected: true,
                };
              } else {
                return { ...time, selected: false };
              }
            });
            setGridData(updatedGridData);
            const selectedTimeStart = parseTime(hourInfo.hora);
            const selectedTimeEnd = parseEndTime(hourInfo.hora);
            setSelectedTime((prev) => ({
              selectedTimeStart,
              selectedTimeEnd,
              monto: hourInfo.monto,
            }));
          }
        } else {
          const parsedSelectedHour = parseEndTime(hourInfo.hora);
          const isSame = parsedSelectedTimeStart.unix() === parsedSelectedHour.unix();
          if (isSame) {
            const updatedGridData = gridData.map((time) => {
              if (time.hora === hourInfo.hora) {
                return {
                  ...time,
                  selected: true,
                };
              } else {
                return { ...time };
              }
            });
            setGridData(updatedGridData);
            const newStartHour = parseTime(hourInfo.hora);
            setSelectedTime((prev) => ({
              ...prev,
              selectedTimeStart: newStartHour,
              monto: parseFloat(prev.monto) + parseFloat(hourInfo.monto),
            }));
          } else {
            const updatedGridData = gridData.map((time) => {
              if (time.hora === hourInfo.hora) {
                return {
                  ...time,
                  selected: true,
                };
              } else {
                return { ...time, selected: false };
              }
            });
            setGridData(updatedGridData);
            const selectedTimeStart = parseTime(hourInfo.hora);
            const selectedTimeEnd = parseEndTime(hourInfo.hora);
            setSelectedTime((prev) => ({
              selectedTimeStart,
              selectedTimeEnd,
              monto: parseFloat(hourInfo.monto),
            }));
          }
        }
      } else {
        const selectedTimeStart = parseTime(hourInfo.hora);
        const selectedTimeEnd = parseEndTime(hourInfo.hora);
        const monto = parseFloat(hourInfo.monto);

        setSelectedTime((prev) => ({
          selectedTimeStart,
          selectedTimeEnd,
          monto,
        }));
        const updatedGridData = gridData.map((time) => {
          if (time.hora === hourInfo.hora) {
            return {
              ...time,
              selected: true,
            };
          } else {
            return { ...time, selected: false };
          }
        });
        setGridData(updatedGridData);
      }
    }
  };

  const isAvailable = (hourIndex) => {
    const hourInfo = gridData[hourIndex];
    if (!hourInfo.available) {
      return false;
    }
    if (!hourInfo.enabled) {
      return false;
    }
    return true;
  };

  const parseEndTime = (hourString) => {
    const mm = moment();
    const time = hourString.split(':');

    const momentHourEnd = mm
      .set('hour', parseInt(time[0], 10) + 1)
      .set('minutes', parseInt(time[1], 10))
      .set('second', parseInt(time[2], 10));
    return momentHourEnd;
  };

  const parseTime = (hourString) => {
    const mm = moment();
    const time = hourString.split(':');

    const momentHour = mm.set('hour', parseInt(time[0], 10)).set('minutes', parseInt(time[1], 10)).set('second', parseInt(time[2], 10));
    return momentHour;
  };

  const defaultTime = () => {
    const datetime = moment(Date.now());
    if (datetime.get('minute') > 30) {
      return moment(
        datetime
          .set('hour', datetime.get('hour') + 1)
          .set('minute', 0)
          .set('second', 0),
        'HH:mm'
      ).format('HH:mm:ss');
    } else {
      return moment(
        datetime
          .set('hour', datetime.get('hour') + 1)
          .set('minute', 0)
          .set('second', 0),
        'HH:mm'
      ).format('HH:mm:ss');
    }
  };

  const setDisabledByTime = () => {
    if (fieldTimeGrid.length > 0) {
      const today = moment().format('YYYY-MM-DD');
      const dateSelected = moment(currentDate).format('YYYY-MM-DD');
      if (today === dateSelected) {
        //console.log({ dt: defaultTime() });
        const enabledFrom = defaultTime().split(':')[0];
        const checkGrid = fieldTimeGrid.map((hourData) => {
          const hour = hourData.hora.split(':')[0];
          if (parseInt(hour, 10) < parseInt(enabledFrom, 10)) {
            //console.log({ a: parseInt(hour, 10), b: parseInt(enabledFrom, 10) });
            return {
              ...hourData,
              enabled: false,
            };
          } else {
            //console.log({ a: parseInt(hour, 10), b: parseInt(enabledFrom, 10) });
            if (parseInt(enabledFrom, 10) === 0) {
              return {
                ...hourData,
                enabled: false,
              };
            } else {
              return {
                ...hourData,
              };
            }
          }
        });
        //console.log({ checkGrid });
        setGridData(checkGrid);
      }
    }
  };

  useLayoutEffect(() => {
    setGridData(fieldTimeGrid);
    setSelectedTime(null);
    manageSelection(null, null, null);
    setDisabledByTime();
  }, [fieldTimeGrid]);

  useEffect(() => {
    if (selectedTime && Object.keys(selectedTime).length > 0) {
      manageSelection(selectedTime.selectedTimeStart.unix(), selectedTime.selectedTimeEnd.unix(), selectedTime.monto.toFixed(2));
    }
  }, [selectedTime]);

  useEffect(() => {
    if (preSelectData && Object.keys(preSelectData).length > 0) {
      const preselectedTimeStart = parseTime(preSelectData.hora);
      const preselectedTimeEnd = parseEndTime(preSelectData.hora);
      setSelectedTime({
        selectedTimeStart: preselectedTimeStart,
        selectedTimeEnd: preselectedTimeEnd,
        monto: parseFloat(preSelectData.monto),
      });
    }
  }, [preSelectData]);

  return (
    <ComponentTimeGrid>
      <Row className='Row-Content--dataTime'>
        <Col span={24}>
          <Row className='Row-title-dataTime'>
            <Col span={7}>
              <IcSun />
              <p>Día</p>
            </Col>
            <Col span={7}>
              <IcSunsent />
              <p>Tarde</p>
            </Col>
            <Col span={7}>
              <IcMoon />
              <p>Noche</p>
            </Col>
          </Row>
          {props.loading ? (
            <>
              <Row>
                <div style={{ paddingTop: '8px', width: '100%' }}>
                  <Skeleton.Button active='true' style={{ height: '268px', width: '100%' }} />
                </div>
              </Row>
            </>
          ) : (
            gridData.length > 0 && (
              <>
                <Row className='Row-grid'>
                  <Col span={7} className={`hour-slot hour-morning ${gridData[1].selected ? 'hour-selected' : ''} ${isAvailable(1) ? 'hour-available' : 'hour-disabled'}`}>
                    <Button block type='text' style={{ margin: '0px' }} disabled={!isAvailable(1)} onClick={() => hourClicked(1)}>
                      06 - 07
                    </Button>
                  </Col>
                  <Col span={7} className={`hour-slot hour-afternoon ${gridData[7].selected ? 'hour-selected' : ''} ${isAvailable(7) ? 'hour-available' : 'hour-disabled'}`}>
                    <Button block type='text' style={{ margin: '0px' }} disabled={!isAvailable(7)} onClick={() => hourClicked(7)}>
                      12 - 01
                    </Button>
                  </Col>
                  <Col span={7} className={`hour-slot hour-night ${gridData[13].selected ? 'hour-selected' : ''} ${isAvailable(13) ? 'hour-available' : 'hour-disabled'}`}>
                    <Button block type='text' style={{ margin: '0px' }} disabled={!isAvailable(13)} onClick={() => hourClicked(13)}>
                      06 - 07
                    </Button>
                  </Col>
                </Row>
                <Row className='Row-grid'>
                  <Col span={7} className={`hour-slot hour-morning ${gridData[2].selected ? 'hour-selected' : ''} ${isAvailable(2) ? 'hour-available' : 'hour-disabled'}`}>
                    <Button block type='text' style={{ margin: '0px' }} disabled={!isAvailable(2)} onClick={() => hourClicked(2)}>
                      07 - 08
                    </Button>
                  </Col>
                  <Col span={7} className={`hour-slot hour-afternoon ${gridData[8].selected ? 'hour-selected' : ''} ${isAvailable(8) ? 'hour-available' : 'hour-disabled'}`}>
                    <Button block type='text' style={{ margin: '0px' }} disabled={!isAvailable(8)} onClick={() => hourClicked(8)}>
                      01 - 02
                    </Button>
                  </Col>
                  <Col span={7} className={`hour-slot hour-night ${gridData[14].selected ? 'hour-selected' : ''} ${isAvailable(14) ? 'hour-available' : 'hour-disabled'}`}>
                    <Button block type='text' style={{ margin: '0px' }} disabled={!isAvailable(14)} onClick={() => hourClicked(14)}>
                      07 - 08
                    </Button>
                  </Col>
                </Row>
                <Row className='Row-grid'>
                  <Col span={7} className={`hour-slot hour-morning ${gridData[3].selected ? 'hour-selected' : ''} ${isAvailable(3) ? 'hour-available' : 'hour-disabled'}`}>
                    <Button block type='text' style={{ margin: '0px' }} disabled={!isAvailable(3)} onClick={() => hourClicked(3)}>
                      08 - 09
                    </Button>
                  </Col>
                  <Col span={7} className={`hour-slot hour-afternoon ${gridData[9].selected ? 'hour-selected' : ''} ${isAvailable(9) ? 'hour-available' : 'hour-disabled'}`}>
                    <Button block type='text' style={{ margin: '0px' }} disabled={!isAvailable(9)} onClick={() => hourClicked(9)}>
                      02 - 03
                    </Button>
                  </Col>
                  <Col span={7} className={`hour-slot hour-night ${gridData[15].selected ? 'hour-selected' : ''} ${isAvailable(15) ? 'hour-available' : 'hour-disabled'}`}>
                    <Button block type='text' style={{ margin: '0px' }} disabled={!isAvailable(15)} onClick={() => hourClicked(15)}>
                      08 - 09
                    </Button>
                  </Col>
                </Row>
                <Row className='Row-grid'>
                  <Col span={7} className={`hour-slot hour-morning ${gridData[4].selected ? 'hour-selected' : ''} ${isAvailable(4) ? 'hour-available' : 'hour-disabled'}`}>
                    <Button block type='text' style={{ margin: '0px' }} disabled={!isAvailable(4)} onClick={() => hourClicked(4)}>
                      09 - 10
                    </Button>
                  </Col>
                  <Col span={7} className={`hour-slot hour-afternoon ${gridData[10].selected ? 'hour-selected' : ''} ${isAvailable(10) ? 'hour-available' : 'hour-disabled'}`}>
                    <Button block type='text' style={{ margin: '0px' }} disabled={!isAvailable(10)} onClick={() => hourClicked(10)}>
                      03 - 04
                    </Button>
                  </Col>
                  <Col span={7} className={`hour-slot hour-night ${gridData[16].selected ? 'hour-selected' : ''} ${isAvailable(16) ? 'hour-available' : 'hour-disabled'}`}>
                    <Button block type='text' style={{ margin: '0px' }} disabled={!isAvailable(16)} onClick={() => hourClicked(16)}>
                      09 - 10
                    </Button>
                  </Col>
                </Row>
                <Row className='Row-grid'>
                  <Col span={7} className={`hour-slot hour-morning ${gridData[5].selected ? 'hour-selected' : ''} ${isAvailable(5) ? 'hour-available' : 'hour-disabled'}`}>
                    <Button block type='text' style={{ margin: '0px' }} disabled={!isAvailable(5)} onClick={() => hourClicked(5)}>
                      10 - 11
                    </Button>
                  </Col>
                  <Col span={7} className={`hour-slot hour-afternoon ${gridData[11].selected ? 'hour-selected' : ''} ${isAvailable(11) ? 'hour-available' : 'hour-disabled'}`}>
                    <Button block type='text' style={{ margin: '0px' }} disabled={!isAvailable(11)} onClick={() => hourClicked(11)}>
                      04 - 05
                    </Button>
                  </Col>
                  <Col span={7} className={`hour-slot hour-night ${gridData[17].selected ? 'hour-selected' : ''} ${isAvailable(17) ? 'hour-available' : 'hour-disabled'}`}>
                    <Button block type='text' style={{ margin: '0px' }} disabled={!isAvailable(17)} onClick={() => hourClicked(17)}>
                      10 - 11
                    </Button>
                  </Col>
                </Row>
                <Row className='Row-grid'>
                  <Col span={7} className={`hour-slot hour-morning ${gridData[6].selected ? 'hour-selected' : ''} ${isAvailable(6) ? 'hour-available' : 'hour-disabled'}`}>
                    <Button block type='text' style={{ margin: '0px' }} disabled={!isAvailable(6)} onClick={() => hourClicked(6)}>
                      11 - 12
                    </Button>
                  </Col>
                  <Col span={7} className={`hour-slot hour-afternoon ${gridData[12].selected ? 'hour-selected' : ''} ${isAvailable(12) ? 'hour-available' : 'hour-disabled'}`}>
                    <Button block type='text' style={{ margin: '0px' }} disabled={!isAvailable(12)} onClick={() => hourClicked(12)}>
                      05 - 06
                    </Button>
                  </Col>
                  <Col span={7} className={`hour-slot hour-night ${gridData[18].selected ? 'hour-selected' : ''} ${isAvailable(18) ? 'hour-available' : 'hour-disabled'}`}>
                    <Button block type='text' style={{ margin: '0px' }} disabled={!isAvailable(18)} onClick={() => hourClicked(18)}>
                      11 - 12
                    </Button>
                  </Col>
                </Row>
              </>
            )
          )}
        </Col>
      </Row>
    </ComponentTimeGrid>
  );
};

export default TimeGrid;
